import { defineStore } from "pinia";

const DEFAULT_REIMBURSEMENT_VALUE = 45;

export const useReimbursementModalStore = defineStore("reimbursementModalStore", () => {
  const isVisible = ref(false);

  const reimbursement = ref(DEFAULT_REIMBURSEMENT_VALUE);

  function toggleModal() {
    isVisible.value = !isVisible.value;
  }

  function setReimbursement(reimbursementValue: number) {
    reimbursement.value = reimbursementValue;
  }

  function $reset() {
    reimbursement.value = DEFAULT_REIMBURSEMENT_VALUE;
  }

  return { $reset, isVisible, reimbursement, setReimbursement, toggleModal };
});
